<!--
    描述：首页 --- 更多审计动态
    时间：2021-06-01
    Created by 王帅
-->
<template>
    <div class="filter-layout">
        <!-- 筛选搜索栏 -->
        <div class="filter-box">
            <el-form
                ref="elForm"
                :model="searchObj"
                size="small"
                label-width="110px"
                v-show="filterShow"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="相关事项" prop="issues">
                            <el-input
                                v-model="searchObj.issues"
                                placeholder="请输入相关事项"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="项目名称" prop="projectName">
                            <el-input
                                v-model="searchObj.projectName"
                                placeholder="请输入项目名称"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="发起人" prop="userId">
                            <el-select v-model="searchObj.userId">
                                <el-option
                                    v-for="(item, index) in sponsorList"
                                    :key="'发起人' + index"
                                    :label="item.sname"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="发起单位" prop="deptId">
                            <el-select v-model="searchObj.deptId">
                                <el-option
                                    v-for="(item, index) in sponsorUnit"
                                    :key="'发起单位' + index"
                                    :label="item.sname"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="单位类型" prop="unitType">
                            <el-select v-model="searchObj.unitType">
                                <el-option
                                    v-for="(item, index) in unitList"
                                    :key="'单位类型' + index"
                                    :label="item.sname"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item size="small" label-width="0" class="text-right">
                            <el-button @click="resetForm">重置</el-button>
                            <el-button class="btn-blue" @click="getTableData(1)"
                                >查询</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <!-- 筛选折叠按钮 -->
            <div class="control-btn" @click="filterShow = !filterShow">
                <span v-if="filterShow">
                    点击收起
                    <svg-icon icon-class="pack-up" class="c-icon ml5"></svg-icon>
                </span>
                <span v-else
                    >筛选
                    <svg-icon icon-class="pack-down" class="c-icon ml5"></svg-icon>
                </span>
            </div>
        </div>

        <!-- 具体内容 -->
        <div class="filter-content primary-layout">
            <div class="primary-header">
                <div class="left-text">合计审计动态: {{ sumTotal }} 条</div>

                <!-- <div class="btnGroups">
                    <el-button
                        size="small"
                        class="btn-success"
                        v-if="roleBtns.includes('add_project')"
                        @click.native="handleTool('add')"
                        >新建工程项目</el-button
                    >
                    <el-button
                        size="small"
                        class="btn-blue"
                        @click.native="showTagsDialog('add')"
                        >添加标签</el-button
                    >
                    <el-button size="small" @click="exportExcel">导出Excel</el-button>
                </div> -->
            </div>
            <!-- 底部表格 -->
            <div class="primary-content w-table-layout w-table-page" v-loading="loading">
                <div class="w-table-content">
                    <vxe-table
                        :data="tableData"
                        align="center"
                        header-align="center"
                        border
                        resizable
                        ref="auditTable"
                        highlight-hover-row
                        show-header-overflow
                        show-overflow
                        height="100%"
                    >
                        <vxe-table-column
                            type="seq"
                            width="60"
                            title="序号"
                        ></vxe-table-column>
                        <vxe-table-column
                            title="相关事项"
                            field="issues"
                            width="130px"
                        ></vxe-table-column>
                        <vxe-table-column
                            title="内容"
                            field="content"
                            align="left"
                            header-align="center"
                            min-width="200px"
                        ></vxe-table-column>
                        <vxe-table-column
                            title="项目名称"
                            align="left"
                            header-align="center"
                            field="projectName"
                            width="200px"
                        >
                        </vxe-table-column>
                        <vxe-table-column
                            title="发起人"
                            field="userName"
                            width="150px"
                        ></vxe-table-column>
                        <vxe-table-column
                            title="发起单位"
                            field="companyName"
                            align="left"
                            header-align="center"
                            width="170px"
                        >
                            <template #default="{ row }">
                                <el-tooltip effect="dark" 
                                            :content="row.companyName" 
                                            placement="top">
                                    <span class="vxe-cell-label">{{ row.companyShortName }}</span>
                                </el-tooltip>
                            </template>
                        </vxe-table-column> 
                        <vxe-table-column
                            title="单位类型"
                            field="limitName"
                            width="130px"
                        ></vxe-table-column>
                        <vxe-table-column
                            title="创建时间"
                            field="createDate"
                            width="160px"
                        ></vxe-table-column>
                    </vxe-table>
                </div>
                <div class="w-table-pager text-center">
                    <vxe-pager
                        :current-page="searchObj.current"
                        :page-size="searchObj.size"
                        :total="total"
                        :layouts="[
                            'PrevPage',
                            'JumpNumber',
                            'NextPage',
                            'Sizes',
                            'Total',
                            'FullJump',
                        ]"
                        align="center"
                        @page-change="handleCurrentChange"
                        class="footer-container"
                    >
                    </vxe-pager>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as HomeApi from "api/home/homeIndex";
export default {
    name: "",
    data() {
        return {
            filterShow: true,
            searchObj: {
                size: 10,
                current: 1,
                projectId: "",
                issues: "", // 相关单位
                projectName: "", // 项目名称
                userId: "", // 相关单位
                deptId: "", // 相关单位
                unitType: "", // 相关单位
            },
            // 查询条件、
            sponsorList: [], // 发起人
            sponsorUnit: [], // 发起单位
            unitList: [], // 单位类型
            // 表格相关
            tableData: [],
            loading: false,
            total: 0,
            sumTotal: 0,
        };
    },
    created() {
        this.getTableData(1, 'first');

        this.getQueryDict()
    },
    methods: {
        // 查询表格数据
        getTableData(page, first) {
            let params = this.$utils.clone(this.searchObj, true);
            page ? (params.current = page) : "";

            HomeApi.getAuditData(params)
                .then((res) => {
                    if (first) {
                        this.sumTotal = parseInt(res.data.pageList.total);
                    }
                    this.tableData = res.data.pageList.records;
                    this.total = parseInt(res.data.pageList.total);
                })
                .catch((err) => {});
        },

        // 获取查询条件下拉码表
        getQueryDict() {
            this.sponsorList = [];
            this.sponsorUnit = [];
            this.unitList = [];

            HomeApi.getQueryList({})
                .then((res) => {
                    this.sponsorList = res.data.sysUserList;
                    this.sponsorUnit = res.data.sysTenantList;
                    this.unitList = res.data.sysLimits;
                })
                .catch((err) => {});
        },

        // 点击重置
        resetForm() {
            this.$refs["elForm"].resetFields();

            this.searchObj.current = 1
            this.searchObj.size = 10
            this.getTableData();
            this.getQueryDict()
        },

        // 切换当前页码
        handleCurrentChange({ currentPage, pageSize }) {
            this.searchObj.current = currentPage;
            this.searchObj.size = pageSize;
            this.getTableData();
        },
    },
};
</script>

<style scoped lang="less">
.filter-box {
    padding-top: 10px;
}
</style>
